import './App.css';
import Signup from './Signup';

function App() {
  return (
    <div className="App">
        <Signup />
    </div>
  );
}

export default App;
