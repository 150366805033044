export const schemas = {
    postBody : {
        "externalId": "",
        "firstName": "",
        "lastName": "",
        "title": "",
        "emails": [
            {
            "emailAddress": "",
            "emailType": "Home"    
            }
        ],
        "phones": [
            {
                "phoneNumber": "",
                "extension": "",
                "phoneType": "Home"
            }
        ],
        "addresses": [
            {
                "address": "",
                "address2": "",
                "city": "Ormond Beach",
                "stateProvince": "FL",
                "zipPostalCode": "",
                "country": "US",
                "addressType": "Home",
                "thundercall": {
                    "phoneSetting": {
                        "name": "Home",
                        "phoneType": "Home",
                        "email": 0,
                        "enableText": false
                    },
                    "warningTypes": [
                        
                    ]
                }
            }
        ],
        "locationIds": []
    }
}