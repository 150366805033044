export const globals= {
    API_URL: "https://dataload.voloos.com/api/products/",
    usStates: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN",
              "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", 
              "NJ", "NM", "NY", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT",
               "VA", "WA", "WV", "WI", "WY"],
    WebsiteBanner: process.env.REACT_APP_HEADER,

    apiCompanyProductId: "7J8vvMy85MkrcQLqM6ZZTl",
    AuthorizationValue: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4MGU0NGFlMS1iNTRmLTRhYWMtOGQyNy03MzU1Y2MyZGU3NWQiLCJqdGkiOiJlMDNlYTQ5OC1lOTUxLTRkMWUtODkyMS03OThmNzNlMjRiY2EiLCJpYXQiOjE2NTI5NzA4NjQsIlJvbGUiOiJQdWJsaWNBcGlVc2VyIiwiQ29tcGFueSI6IjBjODA5YjNmLWE1NDYtNGU3Yy04M2JmLThmYmUwZjgwYjBlMSIsIm5iZiI6MTY1Mjk3MDg2MywiZXhwIjoyMTQ1OTE2Nzk5LCJpc3MiOiJodHRwczovL2RhdGFsb2FkLnZvbG9vcy5jb20vIiwiYXVkIjoiaHR0cHM6Ly9kYXRhbG9hZC52b2xvb3MuY29tLyJ9.zEEzDAaFdlMSuQIWOG-Du5ejc4v1TMKiRh_A4sjn7ss",
    ApiCompanyId: "1w1ZyzqeL4OAGpiTBS5SKX"
}